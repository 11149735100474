import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Doppelganger.tk is a `}<a parentName="p" {...{
        "href": "https://doppelganger.tk"
      }}>{`site`}</a>{` and an `}<a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=tk.doppelganger"
      }}>{`Android app`}</a>{` that lets you check whether a username is taken across a variety of sites. You can filter for found or not found sites, sort by popularity, filter for a specific site, or only show results for sites you've favorited. It also suggests alternate / past usernames that could be claimed. Username info is cached with a lifetime of 5 hours, so any changes could take up to 5 hours to update.`}</p>
    <p><a parentName="p" {...{
        "href": "/images/doppelganger.tk.png"
      }}><img parentName="a" {...{
          "src": "/images/doppelganger.tk.png",
          "alt": "Doppelganger Screenshot"
        }}></img></a></p>
    <p>{`It uses `}<a parentName="p" {...{
        "href": "https://github.com/sherlock-project/sherlock"
      }}>{`Sherlock`}</a>{` behind-the-scenes to look up usernames. The backend is written in Node.js and hosted on `}<a parentName="p" {...{
        "href": "https://heroku.com"
      }}>{`Heroku`}</a>{`. The frontend uses `}<a parentName="p" {...{
        "href": "https://vuejs.org/"
      }}>{`Vue.js`}</a>{` and is hosted on `}<a parentName="p" {...{
        "href": "https://firebase.google.com/"
      }}>{`Firebase`}</a>{` hosting. Doppelganger.tk is open source (`}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/doppelganger"
      }}>{`client`}</a>{` `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/username-lookup"
      }}>{`server`}</a>{`).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      